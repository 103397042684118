import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { CONTACT_PHONE_URL } from '~/constants';

import * as styles from './Home.module.scss';

const Home: React.FC = () => {
  return (
    <>
      <section className={styles.root}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Twój uśmiech
            <br />
            jest dla nas ważny.
          </h1>
          <p className={styles.subtitle}>Ponad 20 letnie doświadczenie i dziesiątki tysięcy zadowolonych pacjentów.</p>
          <a href={`tel:${CONTACT_PHONE_URL}`} className={styles.button}>
            Zadzwoń i umów wizytę
          </a>
        </div>
        <div className={styles.image}>
          <StaticImage
            src='../../assets/photos/gabinet.png'
            alt='Gabinet'
            className={styles.cabinet}
            placeholder='tracedSVG'
            objectFit='contain'
            quality={95}
          />
        </div>
      </section>

      <section className={styles.motto}>
        <h2>Nasze motto:</h2>
        <blockquote className={styles.quote}>
          &quot;Uśmiech jest najlepszą kosmetyką do użytku zewnętrznego i najlepszym lekarstwem do użytku
          wewnętrznego.&quot;
        </blockquote>
        <div className={styles.author}>Phil Bosman</div>
      </section>
    </>
  );
};

export default Home;
