// extracted by mini-css-extract-plugin
export var root = "Home-module--root--15NYO";
export var content = "Home-module--content--1E1zR";
export var image = "Home-module--image--QAAgm";
export var cabinet = "Home-module--cabinet--1Fqfn";
export var subtitle = "Home-module--subtitle--1kjsZ";
export var button = "Home-module--button--3is0g";
export var motto = "Home-module--motto--2hqob";
export var quote = "Home-module--quote--3xpLl";
export var author = "Home-module--author--2rV6u";
export var title = "Home-module--title--3uOp7";